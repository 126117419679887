define('modules/wawi/product/controllers/ProductWizardController',[
	'modules/module',
	'modules/wawi/product/controllers/ProductFormController',
	'directives/sideModalForm/sideModalForm',
	'directives/contentLocalesForm/contentLocalesForm',
	'directives/languageForm/languageForm',
	'services/NavigationService',
	'services/ShopService',
	'services/UserService',
	'services/MetadataService',
	'services/CategoryService',
	'services/NotificationService',
	'services/MetadataUnpagedService',
	'services/AttributeService',
	'services/MetadataSetUnpagedService',
	'services/ProductMetadataValueSetService',
	'services/ProductService',
	'services/ValidationService',
], function (module) {
	'use strict';
	module.controller('ProductWizardController', [
		'$scope',
		'$q',
		'$state',
		'$injector',
		'BASE_TEMPLATES_PATH',
		'LOCALES',
		'TaxService',
		'ShopService',
		'MetadataUnpagedService',
		'UserService',
		'MetadataService',
		'CategoryService',
		'NotificationService',
		'AttributeService',
		'NavigationService',
		'MetadataSetUnpagedService',
		'ProductMetadataValueSetService',
		'ProductService',
		'ValidationService',
		'ShopService',
		'$controller',
		'$timeout',
		ProductWizardController,
	]);
});

function ProductWizardController(
	$scope,
	$q,
	$state,
	$injector,
	BASE_TEMPLATES_PATH,
	LOCALES,
	TaxService,
	ShopService,
	MetadataUnpagedService,
	UserService,
	MetadataService,
	CategoryService,
	NotificationService,
	AttributeService,
	NavigationService,
	MetadataSetUnpagedService,
	ProductMetadataValueSetService,
	ProductService,
	ValidationService,
	ShopService,
	$controller,
	$timeout
) {
	angular.extend(
		this,
		$controller('ProductFormController', {
			$scope: $scope,
		})
	);
	$scope.expanded = false;
	$scope.pageSize = 10;
	$scope.currentPage = 1;
	$scope.w = { selectedMetadata: [], sync: [] };
	$scope.addOnSellingProducts = [];
	$scope.contentEditorTabs = ['shortDescription', 'longDescription'];
	$scope.activeContentTab = $scope.contentEditorTabs[0];
	$scope.state = {
		publishState: true,
	};
	$scope.entity = {};
	$scope.entity.showPriceCatalog = false;
	$scope.entity.showPriceSearch = false;
	$scope.entity.showPriceDetail = false;
	$scope.motorCodes = [];
	$scope.EntityMetadataSets = [];

	$scope.checkRange = function () {
		console.log('checkRange');
	};

	$scope.afterInitEntity = (entity) => {
		$scope.getMetadataSets().then(() => {
			// defining  metadata objects to avoid "undefined" error
			$scope.w.selectedMetadata = [];
			for (let i in $scope.tabLocales) {
				if (!$scope.w.selectedMetadata[i]) {
					$scope.w.selectedMetadata[i] = [];
				}

				if (!$scope.entity.locales[i]) {
					$scope.entity.locales[i] = {};
				}

				if ($scope.entity.locales[i].metadataSets && $scope.entity.locales[i].metadataSets[0]) {
					if ($scope.entity.locales[i].metadataSets[0].productMetadataValueSets) {
						$scope.EntityMetadataSets[i] = { metadataSets: $scope.entity.locales[i].metadataSets };
					}

					if ($scope.EntityMetadataSets[0] && $scope.EntityMetadataSets[0].metadataSets) {
						$scope.displayedVehicles = $scope.EntityMetadataSets[0].metadataSets[0].productMetadataValueSets.filter(
							(x, i) => {
								return i >= 10 * ($scope.currentPage - 1) && i < 10 * $scope.currentPage;
							}
						);
					}
					$scope.w.selectedMetadataSetId = $scope.entity.locales[i].metadataSets[0].metadataSetId;
				}
				// $scope.motorCodes.push({
				// 	identifier: 'Motorkennbuchstabe',
				// 	name: 'Motorkennbuchstabe',
				// 	productMetadataValues: [],
				// });
			}
		});
	};

	const afterInitLocales = () => {
		ShopService.findOne(UserService.getShop()).then(() => {
			const shop = ShopService.getOne();
			$scope.entity.tax = $scope.entity.tax || shop.defaultTax;
			$scope.entity.taxId = $scope.entity.taxId || (shop && shop.defaultTax ? shop.defaultTax.id : null);
		});

		$scope.vehicleFilters = ProductMetadataValueSetService.getVehicleFilters();
		AttributeService.findAll().then(function () {
			$scope.attributes = AttributeService.getAll(true);
			$scope.setDefaultOptionsWithAttr();

			//If attributes exist then define empty arrays to objects to avoid "undefined" error,
			//otherwise just define empty object of attributes for the same reason
			if ($scope.entity.selectedAttributes === undefined || $scope.entity.selectedAttributes.length === 0) {
				$scope.entity.selectedAttributes = [{}];
			}
		});

		// AddOn Selling products default setting
		$scope.setSellingProducts();

		$scope.entity.categoryIds = $scope.entity.categoryIds || [];

		$scope.entity.locales = $scope.entity.locales || [];

		TaxService.findAll().then(function () {
			$scope.taxes = TaxService.getAll(true);
		});

		MetadataUnpagedService.findAll().then(function () {
			$scope.metadatas = MetadataUnpagedService.getAll(true).data;
			$scope.filteredMetadata = Object.assign([], $scope.metadatas);

			$scope.metaToSet = [];
			for (let locale in $scope.tabLocales) {
				if (!$scope.entity.locales[locale]) {
					$scope.entity.locales[locale] = {};
					$scope.entity.locales[locale].metadata = [{}];
				} else if (!$scope.entity.locales[locale].metadata) {
					$scope.entity.locales[locale].metadata = [{}];
				} else {
					if ($scope.entity.locales[locale].metadata && $scope.entity.locales[locale].metadata[0]) {
						$scope.entity.locales[locale].metadata.map((metadate, j) => {
							let meta = $scope.metadatas.find((x) => x.id == metadate.metadataNameId);
							setTimeout(() => {
								MetadataService.findOne(meta.id).then(function () {
									if (!$scope.w.selectedMetadata[locale]) {
										$scope.w.selectedMetadata.push([]);
									}
									$scope.w.selectedMetadata[locale][j] = {
										dropDownValueId: metadate.productMetadataValues[0].metadataValueId,
										identifier: metadate.identifier,
										inputType: meta.inputType,
										metadataId: metadate.metadataNameId,
										metadataNameId: metadate.metadataNameId,
										metadataObjects: MetadataService.getOne(),
										productMetadataValues: metadate.productMetadataValues,
										textValue: metadate.productMetadataValues[0]
											? metadate.productMetadataValues[0].value
											: '',
									};
								});
							}, 5000);
						});
					}
				}
			}
		});

		$scope.entity.prices = $scope.entity.prices || [Object.assign({}, emptyPriceObj)];

		CategoryService.findAll().then(function () {
			$scope.categories = CategoryService.getAll().data || CategoryService.getAll();
			$scope.categories = $scope.categories.map((item) => {
				item.isActive = $scope.entity.categoryIds.includes(item.id);
				return item;
			});
		});

		$scope.getMetadataSets().then(() => {
			// defining metadata objects for each locale
			$scope.w.selectedMetadata = [];
			for (let i in $scope.tabLocales) {
				if (!$scope.w.selectedMetadata[i]) {
					$scope.w.selectedMetadata[i] = [];
				}
			}
		});
	};

	$scope.localesInitialised = false;

	function initShopLocales() {
		ShopService.findShopLocales().then(() => {
			$scope.tabLocales = ShopService.getShopLocales(true);

			$scope.activeLocale = $scope.tabLocales.find((x) => x.isDefault);
			$scope.localesInitialised = true;

			// whatever
			$scope.tabLocales.sort((lh, rh) => lh.id - rh.id);

			afterInitLocales();
		});
	}
	initShopLocales();

	$scope.isActiveVehicleFilter = (filter) => {
		return $scope.vehicleFilters.includes(filter);
	};

	//
	// TINYMCE content editor configuration and localization
	$scope.defineTinyLanguage = (loc) => {
		switch (loc.locale) {
			case 'fr':
				return 'fr_FR';
			case 'cz':
				return 'cs';
			default:
				return loc.locale;
		}
	};

	$scope.getTinyOptions = (loc) => {
		return {
			theme: 'modern',
			height: '500',
			convert_urls: false,
			relative_urls: false,
			remove_script_host: false,
			plugins: [
				'link advlist lists image charmap print preview hr anchor pagebreak',
				'searchreplace wordcount visualblocks visualchars code fullscreen',
				'insertdatetime media nonbreaking save table contextmenu directionality',
				'emoticons template paste textcolor colorpicker textpattern imagetools',
				'fullpage moodiaimage',
			],
			menubar: 'insert | tools',
			toolbar1:
				'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link moodiaimage',
			toolbar2: 'print preview media | forecolor backcolor emoticons | fontsizeselect | fontselect | code',
			image_advtab: true,
			language: $scope.defineTinyLanguage(loc),
		};
	};

	$scope.setContentBuilderTab = (arg) => {
		$scope.activeContentTab = arg;
	};

	// switch for tinymce

	let emptyPriceObj = {
		customerGroup: { id: 1 }, //to Change
		fromAmount: undefined,
		toAmount: undefined,
		sellingPrice: undefined,
		shippingTimeFrom: undefined,
		shippingTimeTo: undefined,
		pseudoPrice: undefined,
	};

	// Product types defining and setting

	$scope.productTypes = [
		{ name: 'WAWI.PRODUCT.DEFAULT_PRODUCT', type: 'SIMPLE' },
		{ name: 'WAWI.PRODUCT.CONFIGURABLE_PRODUCT', type: 'CONFIGURABLE' },
		{ name: 'WAWI.PRODUCT.BUNDLE_PRODUCT', type: 'BINDLE' },
	];

	// On metadata select input change that function runs
	$scope.$watch(
		'w.selectedMetadata',
		(newValue, oldValue) => {
			let refreshMetadate = false;

			for (let locale in $scope.w.selectedMetadata) {
				if ($scope.w.selectedMetadata[locale]) {
					for (let i = 0; i < $scope.w.selectedMetadata[locale].length; i++) {
						for (let j = i + 1; j < $scope.w.selectedMetadata[locale].length; j++) {
							if (
								$scope.w.selectedMetadata[locale][i] &&
								$scope.w.selectedMetadata[locale][j] &&
								$scope.w.selectedMetadata[locale][i].metadataId ===
								$scope.w.selectedMetadata[locale][j].metadataId
							) {
								$scope.w.selectedMetadata[locale].splice(j, 1);
								NotificationService.notifyWarning({
									statusText: 'DUPLICATES NOT ALLOWED',
								});
								return;
							}
						}

						if (oldValue && oldValue.length) {
							if (
								!oldValue[locale][i] ||
								(newValue[locale][i] &&
									oldValue[locale][i] &&
									newValue[locale][i].metadataId !== oldValue[locale][i].metadataId)
							) {
								refreshMetadate = true;
							}
						}
					}
				}
			}

			if (refreshMetadate) {
				$scope.getMetadata(newValue, oldValue);
			}
		},
		true
	);

	$scope.addMotorCode = () => {
		$scope.motorCodes.push('');
	};

	$scope.removeMotorCode = (index) => {
		$scope.motorCodes.splice(index, 1);
	};

	$scope.addMetadata = (metadate) => {
		for (var i = 0; i < $scope.w.selectedMetadata.length; i++) {
			$scope.w.selectedMetadata[i].push({
				metadataId: metadate.id,
				name: metadate.name,
				inputType: metadate.inputType,
				identifier: metadate.identifier,
				productMetadataValues: [],
			});
		}

		$scope.selectedMetadata = undefined;
	};

	$scope.deleteMetadata = (locale, index) => {
		for (var i = 0; i < $scope.w.selectedMetadata.length; i++) {
			$scope.w.selectedMetadata[i].splice(index, 1);
		}
	};

	$scope.getMetadata = (newVal, oldVal) => {
		for (let localeIndex in $scope.w.selectedMetadata) {
			$scope.w.selectedMetadata[localeIndex].map((meta, i) => {
				if (meta.metadataId) {
					var aaa = !oldVal[localeIndex][i];

					if (
						(newVal[localeIndex][i] && !oldVal[localeIndex][i]) ||
						newVal[localeIndex][i].metadataId !== oldVal[localeIndex][i].metadataId
					) {
						let m = $scope.metadatas.find((x) => x.id == meta.metadataId);

						if (m) {
							meta = Object.assign(meta, {
								metadataNameId: m.id,
								name: m.name,
								inputType: m.inputType,
								identifier: m.identifier,
							});

							if (!meta.productMetadataValues) {
								meta.productMetadataValues = [];
							}

							MetadataService.findOne(m.id).then(function () {
								meta.metadataObjects = MetadataService.getOne();

								if (!meta.metadataObjects.locales[localeIndex]) {
									NotificationService.notifyWarning({
										statusText: 'Nothing to append',
									});
									$scope.w.selectedMetadata[localeIndex][i] = {};
								}

								if (m.inputType == 'MULTISELECT' && !!meta.metadataObjects.locales[localeIndex]) {
									for (
										var k = 0;
										k < meta.metadataObjects.locales[localeIndex].metadataValues.length;
										k++
									) {
										meta.productMetadataValues.push({
											value: meta.metadataObjects.locales[localeIndex].metadataValues[k].value,
											isSet: false,
											metadataValueId:
											meta.metadataObjects.locales[localeIndex].metadataValues[k].id,
										});
									}
								}
							});
						}
					}
				}
			});
		}
	};

	let defineProductMetadataValues = (localeIndex, metaIndex, master) => {
		switch ($scope.w.selectedMetadata[localeIndex][metaIndex].inputType) {
			case 'DROPDOWN': {
				let result = $scope.w.selectedMetadata[localeIndex][metaIndex].metadataObjects.locales[localeIndex]
					? [
						$scope.w.selectedMetadata[localeIndex][metaIndex].metadataObjects.locales[
							localeIndex
							].metadataValues.find(
							(x) => x.id === $scope.w.selectedMetadata[localeIndex][metaIndex].dropDownValueId
						),
					]
					: [];

				return result;
			}
			case 'YES_NO': {
				let result = [{ identifier: master.productMetadataValues[0].identifier }];

				return result;
			}
			case 'TEXTFIELD': {
				return [
					{
						value: !!master
							? master.textValue
							: $scope.w.selectedMetadata[localeIndex][metaIndex].textValue,
					},
				];
			}
			case 'DATE': {
				return [
					{
						value: !!master ? master.value : $scope.w.selectedMetadata[localeIndex][metaIndex].value,
					},
				];
			}
			case 'DOCUMENT':
				// return []; cant check // TO DO
				break;
			case 'TEXTAREA': {
				return [
					{
						value: !!master
							? master.textValue
							: $scope.w.selectedMetadata[localeIndex][metaIndex].textValue,
					},
				];
			}
			case 'MULTISELECT': {
				let filtered = [];
				let final = [];
				filtered = $scope.w.selectedMetadata[localeIndex][metaIndex].productMetadataValues.filter(
					(x) => x.isSet
				);
				for (let variant of filtered) {
					final.push({
						isSet: true,
						metadataValueId: variant.metadataValueId,
						value: variant.value,
					});
				}
				return final;
			}
		}
		return [];
	};

	$scope.isAtLeastOneSellingPricePresent = () => {
		const productPrice = $scope.entity.prices.find(
			(productPrice) => productPrice.sellingPrice !== null && productPrice.sellingPrice !== undefined
		);

		return productPrice !== undefined;
	};

	$scope.validate = function () {
		return ValidationService.validate($scope.entity, {
			name: {
				method: 'isset',
				fieldName: 'Name',
				errorMsg: 'Bitte geben Sie einen Namen in der Standard-Sprache an.',
				isLocaleValue: true,
			},
			taxId: {
				method: 'isset',
				fieldName: 'Steuersatz',
				errorMsg: 'Bitte geben Sie einen Steuersatz an.',
			},
			sellingPrice: {
				method: 'isset',
				fieldName: 'Verkaufspreis',
				errorMsg: 'Für den Verkaufspreis ist kein Wert hinterlegt.',
				customValidate: $scope.isAtLeastOneSellingPricePresent,
			},
		});
	};

	$scope.setLocale = (id) => {
		$scope.activeLocale = $scope.tabLocales.find((x) => x.id === id);
	};

	$scope.isIndexBasedMeta = (metadataName) => {
		if (!metadataName) {
			return false;
		}

		return (
			metadataName.inputType === 'YES_NO' ||
			metadataName.inputType === 'DROPDOWN' ||
			metadataName.inputType === 'MULTISELECT'
		);
	};

	/*$scope.localeIndex = () => {
		return $scope.tabLocales.findIndex((x) => x.id === $scope.activeLocale);
	};*/

	// open ProductMetadataValueSet Form
	$scope.openSideModal = () => {
		$scope.expanded = true;
	};

	$scope.listExpanded = false;
	$scope.openSideList = () => {
		$scope.listExpanded = true;
		// $scope.all.checked = false;
		if ($scope.motorCodes.length) {
			$scope.metadataFilters.engineCodes = $scope.motorCodes;
		}

		$scope.setPage($scope.currentPage, $scope.metadataFilters);
	};

	$scope.closeSideList = () => {
		$scope.listExpanded = false;
	};

	$scope.addPrices = () => {
		let obj = Object.assign({}, emptyPriceObj);
		$scope.entity.prices.push(obj);
	};

	$scope.removePrice = (index) => {
		$scope.entity.prices.splice(index, 1);
	};

	$scope.toggleActiveCategory = (id) => {
		if ($scope.entity.categoryIds.includes(id)) {
			$scope.entity.categoryIds.splice($scope.entity.categoryIds.indexOf(id), 1);
		} else {
			$scope.entity.categoryIds.push(id);
		}
	};

	$scope.getMetadataSets = () => {
		return MetadataSetUnpagedService.findAll().then(function () {
			$scope.metadataSets = MetadataSetUnpagedService.getAll(true).data;
		});
	};

	$scope.getFilterFields = (id) => {
		let result = [];

		if ($scope.metadataSets) {
			for (let item of $scope.metadataSets.find((x) => id === x.id).metadataNames) {
				result.push({
					name: item.name,
					identifier: item.identifier,
					order: item.position,
				});
			}
		}

		return result;
	};
	// following two functions are converting metadatasets from BE to object, to which
	// the productSave request is waiting

	let convertVehicleObjectToMetadataObject = (obj) => {
		let result = [];
		for (let key of Object.keys(obj)) {
			if (key != 'id') {
				const metaName = $scope.metadataSet.metadataNames.find(
					(x) => x.identifier.toLowerCase() === key.toLowerCase()
				);
				result.push({
					identifier: key,
					name: metaName ? metaName.name : '',
					values: [
						{
							identifier: obj[key],
							value: obj[key],
						},
					],
				});
			}
		}

		return result;
	};

	let getValueSetsFromArray = (arr) => {
		let result = [];
		if (arr) {
			for (let i in arr) {
				let obj = {
					identifier: arr[i].id,
					metadata: convertVehicleObjectToMetadataObject(arr[i]),
				};
				result.push(obj);
			}
		}
		return result;
	};

	$scope.all = { checked: false };

	$scope.selectAll = () => {
		if ($scope.all.checked) {
			ProductMetadataValueSetService.fetchVehicles(0, 99999999, $scope.metadataFilters, $scope.motorCodes).then(
				() => {
					let selectedVehicles = ProductMetadataValueSetService.getVehicles();

					for (let loc of $scope.tabLocales) {
						$scope.temporarySelectedVehicles[loc.id - 1].metadataSets[0] = {
							metadataSetId: $scope.w.selectedMetadataSetId,
							name: $scope.metadataSet.name,
							productMetadataValueSets: getValueSetsFromArray(selectedVehicles),
						};

						$scope.BackendMetadataSets[loc.id - 1].metadataSets[0].productMetadataValueSets.map(
							(x) => (x.checked = true)
						);
					}
				}
			);
		} else {
			for (let loc of $scope.tabLocales) {
				$scope.temporarySelectedVehicles[loc.id - 1].metadataSets[0] = {
					metadataSetId: $scope.w.selectedMetadataSetId,
					name: $scope.metadataSet.name,
					productMetadataValueSets: [],
				};

				$scope.BackendMetadataSets[loc.id - 1].metadataSets[0].productMetadataValueSets.map(
					(x) => (x.checked = false)
				);
			}
		}
	};

	$scope.getVehicles = (id, filters) => {
		if (id) {
			$scope.metadataSet = $scope.metadataSets.find((x) => x.id === id);
			//Change later
			ProductMetadataValueSetService.fetchVehicles(
				$scope.currentPage - 1,
				undefined,
				filters,
				$scope.motorCodes
			).then(() => {
				$scope.vehicles = ProductMetadataValueSetService.getVehicles();
				$scope.currentPage = ProductMetadataValueSetService.getCurrentPage();
				$scope.pages = ProductMetadataValueSetService.getPages();
				$scope.totalItems = ProductMetadataValueSetService.getTotalItems();

				for (let loc of $scope.tabLocales) {
					let index = $scope.tabLocales.indexOf(loc);
					$scope.BackendMetadataSets[index].metadataSets[0] = {
						metadataSetId: id,
						name: $scope.metadataSet.name,
						productMetadataValueSets: getValueSetsFromArray($scope.vehicles),
					};

					if ($scope.EntityMetadataSets[index].metadataSets) {
						$scope.EntityMetadataSets[index].metadataSets[0].name = $scope.metadataSet.name;

						// disable confirmed
						$scope.EntityMetadataSets[index].metadataSets[0].productMetadataValueSets.map((item) => {
							if (
								$scope.BackendMetadataSets[index].metadataSets[0].productMetadataValueSets.find(
									(x) => x.identifier == item.identifier
								)
							) {
								$scope.BackendMetadataSets[index].metadataSets[0].productMetadataValueSets.find(
									(x) => x.identifier == item.identifier
								).disabled = true;
							}
						});
					}

					// disable selected
					$scope.temporarySelectedVehicles[index].metadataSets[0].productMetadataValueSets.map((item) => {
						if (
							$scope.BackendMetadataSets[index].metadataSets[0].productMetadataValueSets.find(
								(x) => x.identifier == item.identifier
							)
						) {
							$scope.BackendMetadataSets[index].metadataSets[0].productMetadataValueSets.find(
								(x) => x.identifier == item.identifier
							).checked = true;
						}
					});
				}
			});
		}
	};

	$scope.setPage = (currentPage) => {
		$scope.currentPage = currentPage;
		$scope.getVehicles($scope.w.selectedMetadataSetId, $scope.metadataFilters);
	};

	$scope.metadataFilters = {};

	$scope.filterMetadataValueSets = () => {
		$scope.getVehicles($scope.w.selectedMetadataSetId, $scope.metadataFilters);
	};

	$scope.tableOptions = {};

	$scope.temporarySelect = (parentIndex, index, item) => {
		if (item.checked) {
			$scope.temporarySelectedVehicles[parentIndex].metadataSets[0].productMetadataValueSets.push(item);
		} else {
			$scope.temporarySelectedVehicles[parentIndex].metadataSets[0].productMetadataValueSets.splice(
				$scope.temporarySelectedVehicles[parentIndex].metadataSets[0].productMetadataValueSets.indexOf(item),
				1
			);
		}
	};

	$scope.displayedVehicles = [];

	$scope.getCustomPageElements = (currentPage) => {
		$scope.customPage = currentPage;
		$scope.displayedVehicles = $scope.EntityMetadataSets[0].metadataSets[0].productMetadataValueSets.filter(
			(x, i) => {
				return i >= 10 * (currentPage - 1) && i < 10 * currentPage;
			}
		);
	};

	$scope.confirmSelectedVehicles = () => {
		// do it later

		for (let i in $scope.tabLocales) {
			if ($scope.temporarySelectedVehicles[i] && $scope.temporarySelectedVehicles[i].metadataSets) {
				for (let index in $scope.temporarySelectedVehicles[i].metadataSets[0].productMetadataValueSets) {
					for (let j in $scope.tabLocales) {
						$scope.EntityMetadataSets[j].metadataSets[0].productMetadataValueSets.push(
							$scope.temporarySelectedVehicles[i].metadataSets[0].productMetadataValueSets[index]
						);
					}
					if ($scope.BackendMetadataSets[i].metadataSets[0].productMetadataValueSets[index]) {
						$scope.BackendMetadataSets[i].metadataSets[0].productMetadataValueSets[index].disabled = true;
					}
				}
			}
			$scope.temporarySelectedVehicles[i] = {
				metadataSets: [
					{
						metadasetId: $scope.w.selectedMetadataSetId,
						productMetadataValueSets: [],
					},
				],
			};
		}
		$scope.closeSideList();
		$scope.getCustomPageElements(1);
	};

	//

	$scope.selectVehicle = (parentIndex, index, item) => {
		$scope.BackendMetadataSets[parentIndex].metadataSets[0].productMetadataValueSets[index].disabled = true;
		$scope.EntityMetadataSets[parentIndex].metadataSets[0].productMetadataValueSets.push(item);
	};

	$scope.removeVehicle = (parentIndex, index, item) => {
		if ($scope.customPage === undefined) {
			$scope.customPage = 1;
		}

		index = ($scope.customPage - 1) * 10 + index;
		if (
			item.id &&
			$scope.BackendMetadataSets[parentIndex].metadataSets[0].productMetadataValueSets.find(
				(x) => x.id == item.id
			)
		) {
			$scope.BackendMetadataSets[parentIndex].metadataSets[0].productMetadataValueSets.find(
				(x) => x.id == item.id
			).disabled = false;
			$scope.BackendMetadataSets[parentIndex].metadataSets[0].productMetadataValueSets.find(
				(x) => x.id == item.id
			).checked = false;
		}
		for (var locale of $scope.EntityMetadataSets) {
			for (var set of locale.metadataSets) {
				set.productMetadataValueSets.splice(index, 1);
			}
		}

		$scope.getCustomPageElements($scope.customPage);
	};

	$scope.removeAllVehicles = () => {
		for (var locale of $scope.EntityMetadataSets) {
			for (var set of locale.metadataSets) {
				set.productMetadataValueSets = [];
			}
		}
		$scope.all.checked = false;

		$scope.getCustomPageElements(1);
	};

	$scope.newMetadataSet = {};
	$scope.metadataSetValues = [];

	// Creating new metadataset list item
	$scope.saveMetadataSet = () => {
		let meta = [];

		if ($scope.newMetadataSet) {
			for (let m of Object.keys($scope.newMetadataSet)) {
				if (!!$scope.newMetadataSet[m]) {
					meta.push({
						identifier: m,
						name: $scope.metadataSet.metadataNames.find((x) => x.identifier === m).name,
						values: [
							{
								identifier: $scope.newMetadataSet[m],
								value: $scope.newMetadataSet[m],
							},
						],
					});
				}
			}
		}

		for (let i in $scope.tabLocales) {
			if ($scope.tabLocales[i].id === $scope.activeLocale.id) {
				$scope.EntityMetadataSets[i].metadataSets[0].productMetadataValueSets.push({ metadata: meta });
			}
		}
		//change later
		$scope.expanded = false;
		$scope.newMetadataSet = {};
	};

	// when product template is chosen this function runs
	$scope.BackendMetadataSets = [];
	$scope.temporarySelectedVehicles = [];

	let customSort = ({ data, sortBy, sortField }) => {
		let cleanedData = data.filter((item) => sortBy.includes(item[sortField]));

		const sortByObject = sortBy.reduce((obj, item, index) => {
			return Object.assign({}, obj, { [item]: index });
		}, {});
		return cleanedData.sort((a, b) => {
			return sortByObject[a[sortField]] - sortByObject[b[sortField]];
		});
	};

	let sortBy = [
		'brand',
		'model',
		'engineCode',
		'constructionStart',
		'engineDisplacement',
		'enginePowerKw',
		'hsn',
		'tsn',
	];

	$scope.$watch(
		'w.selectedMetadataSetId',
		(a, b) => {
			$scope.getVehicles(a);
			let fields = $scope.getFilterFields(a);
			$scope.filterFields = customSort({ data: fields, sortBy: sortBy, sortField: 'identifier' });

			for (let i in $scope.tabLocales) {
				if (!$scope.EntityMetadataSets[i]) {
					$scope.EntityMetadataSets[i] = {
						metadataSets: [
							{
								metadataSetId: a,
								productMetadataValueSets: [],
							},
						],
					};
				}

				$scope.BackendMetadataSets[i] = {
					metadataSets: [
						{
							metadasetId: a,
							productMetadataValueSets: [],
						},
					],
				};

				$scope.temporarySelectedVehicles[i] = {
					metadataSets: [
						{
							metadasetId: a,
							productMetadataValueSets: [],
						},
					],
				};
			}
		},
		true
	);

	// {
	// 	brand: result.brand,
	// 	model: result.model,
	// 	constructionStart: result.constructionStart,
	// 	engineCode: result.engineCode,
	// 	cylinders: result.cylinders,
	// 	engineDisplacement: result.engineDisplacement,
	// 	enginePowerKw: result.enginePowerKw,
	// 	hsn: result.hsn,
	// 	tsn: result.tsn,
	// };

	$scope.getProductMetadataValueIndexByValue = function (productMetadataValues, identifier) {
		for (var i = 0; i < productMetadataValues.length; i++) {
			if (productMetadataValues[i].identifier == identifier) {
				return i;
			}
		}
	};

	$scope.addAttribute = function () {
		if ($scope.entity.selectedAttributes == undefined) {
			$scope.entity.selectedAttributes = [];
		}
		$scope.entity.selectedAttributes.push({});
	};

	$scope.removeAttribute = function (index) {
		$scope.entity.selectedAttributes.splice(index, 1);
	};

	$scope.addAttributeOption = function (key) {
		if ($scope.entity.selectedAttributes[key].optionIds == undefined) {
			$scope.entity.selectedAttributes[key].optionIds = [];
		}
		$scope.entity.selectedAttributes[key].optionIds.push({});
	};

	$scope.removeAttributeOption = function (key, index) {
		$scope.entity.selectedAttributes[key].optionIds.splice(index, 1);
	};

	$scope.getOptions = function (id) {
		if (!angular.isArray($scope.attributes)) {
			return;
		}

		for (var i = 0; i < $scope.attributes.length; i++) {
			if ($scope.attributes[i].id == id) {
				return $scope.attributes[i].options;
			}
		}
	};

	/*  default option selection*/
	$scope.defaultOptionsWithAttr = [];
	$scope.entity.defaultAttributeOptions = [];
	$scope.selectedDefaultOptionsWithAttr = [];
	$scope.setDefaultOptionsWithAttr = function () {
		if (!$scope.entity.selectedAttributes) {
			return;
		}

		for (var i = 0; i < $scope.entity.selectedAttributes.length; i++) {
			var options = $scope.getDefaultOptionsOfAttr($scope.entity.selectedAttributes[i].attributeId);
			$scope.defaultOptionsWithAttr.push({
				attributeId: $scope.entity.selectedAttributes[i].attributeId,
				options: options,
			});

			// set selected default
			var selectedObj = { attributeId: $scope.entity.selectedAttributes[i].attributeId, optionId: undefined };

			for (var j = 0; j < $scope.entity.defaultAttributeOptions.length; j++) {
				if (
					$scope.entity.defaultAttributeOptions[j].attributeId ==
					$scope.entity.selectedAttributes[i].attributeId
				) {
					for (var k = 0; k < options.length; k++) {
						if (options[k].id == $scope.entity.defaultAttributeOptions[j].optionId) {
							selectedObj.optionId = options[k].id;
						}
					}
				}
			}
			$scope.selectedDefaultOptionsWithAttr.push(selectedObj);
		}
	};

	$scope.getDefaultOptionsOfAttr = function (id) {
		var allOptions = $scope.getOptions(id);
		if (!angular.isArray(allOptions) || allOptions.length === 0) {
			return [];
		}

		var defaultOptions = [];
		for (var i = 0; i < $scope.entity.selectedAttributes.length; i++) {
			if ($scope.entity.selectedAttributes[i].attributeId == id) {
				for (var j = 0; allOptions && j < allOptions.length; j++) {
					for (var k = 0; k < $scope.entity.selectedAttributes[i].optionIds.length; k++) {
						if ($scope.entity.selectedAttributes[i].optionIds[k] === allOptions[j].id) {
							defaultOptions.push(allOptions[k]);
						}
					}
				}
			}
		}
		return defaultOptions;
	};

	$scope.removeDefaultSelection = function (index) {
		$scope.selectedDefaultOptionsWithAttr[index].optionId = undefined;
	};

	// ADD ON SELLING PRODUCTS

	$scope.productService = ProductService;

	$scope.addOnSellingTableOptions = {};
	$scope.addOnSellingTableOptions.name = 'Add On Selling Produkte';
	$scope.addOnSellingTableOptions.fields = [
		{
			id: 'NAME',
			heading: 'FIELDS.NAME',
			attribute: 'name',
		},
		{
			id: 'SKU',
			heading: 'FIELDS.SKU',
			attribute: 'sku',
		},
	];

	$scope.setSellingProducts = function () {
		$scope.entity.crossSellingProducts = [];
		$scope.entity.upSellingProducts = [];
		$scope.entity.addOnSellingProducts = [];
		if ($scope.entity.sellingProducts != undefined) {
			for (var i = 0; i < $scope.entity.sellingProducts.length; i++) {
				var sellingProduct = $scope.entity.sellingProducts[i];
				if (sellingProduct.sellingType == 'ADD_ON_SELLING') {
					$scope.entity.addOnSellingProducts.push(sellingProduct);
				}
			}
		}
	};

	$scope.productService.setCurrentPage(0).then(function () {
		const filters = [{ filterType: 'pureAddOn', filterCond: 'Ja', filterValue: 1 }];
		$scope.products = $scope.productService.filterForBulkUpdate(filters, []).then(() => {
			$scope.products = $scope.productService.getBulkUpdateProducts().data;
		});
	});

	$scope.checkIfFilterIncludesMetadata = (metadata) => {
		return $scope.metadataFilters.order ? $scope.metadataFilters.order.includes(metadata) : false;
	};

	$scope.isFilterAscending = (filter) => {
		return filter ? filter.includes('ASC') : false;
	};

	// ADD ON SELLING PRODUCTS

	$scope.sortVehicles = (metadata) => {
		let currentOrderFilter = $scope.metadataFilters.order;
		if (currentOrderFilter) {
			if (currentOrderFilter.includes(metadata)) {
				if (currentOrderFilter.includes('ASC')) {
					$scope.metadataFilters.order = metadata + ' DESC';
				} else {
					$scope.metadataFilters.order = metadata + ' ASC';
				}
			} else {
				$scope.metadataFilters.order = metadata + ' ASC';
			}
		} else {
			$scope.metadataFilters.order = metadata + ' ASC';
		}

		$scope.getVehicles($scope.w.selectedMetadataSetId, $scope.metadataFilters);
	};

	$scope.onDrop = function ($event, $data, image) {
		$scope.entity.productImages.push({
			isCatalogImage: false,
			isDetailImage: false,
			isInActive: false,
			isSearchImage: false,
			image: $data,
			productSku: $scope.entity.sku,
		});
	};

	$scope.removeImage = (image) => {
		let index = $scope.entity.productImages.indexOf(image);
		$scope.entity.productImages.splice(index, 1);
	};

	// Cleaning the trash, assigning values to $scope.entity object and saving data
	$scope.save = () => {
		$scope.entity.tax = $scope.taxes.find((x) => x.id === $scope.entity.taxId);
		$scope.entity.publishState = $scope.state.publishState ? 'ACTIVE' : 'MAINTENANCE';

		if (!$scope.validate()) {
			NotificationService.notifyValidationErrors(ValidationService.getErrors());

			return;
		}

		let attrs = [];
		for (let attr in $scope.entity.selectedAttributes) {
			if (!!$scope.entity.selectedAttributes[attr].attributeId) {
				attrs.push($scope.entity.selectedAttributes[attr]);
			}
		}

		if (attrs.length === 0) {
			delete $scope.entity.selectedAttributes;
		} else {
			$scope.entity.selectedAttributes = attrs;
		}

		let addOn = [];
		for (let i in $scope.entity.addOnSellingProducts) {
			$scope.entity.addOnSellingProducts[i].sellingType = 'ADD_ON_SELLING';
			if (!!$scope.entity.addOnSellingProducts[i].productId) {
				addOn.push($scope.entity.addOnSellingProducts[i]);
			}
			if (!$scope.entity.addOnSellingProducts[i].productId && $scope.entity.addOnSellingProducts[i].id) {
				$scope.entity.addOnSellingProducts[i].productId = $scope.entity.addOnSellingProducts[i].id;

				addOn.push($scope.entity.addOnSellingProducts[i]);
			}
		}

		if (addOn.length == 0) {
			delete $scope.entity.addOnSellingProducts;
		} else {
			$scope.entity.addOnSellingProducts = addOn;
		}

		$scope.entity.sellingProducts = $scope.entity.addOnSellingProducts;

		for (let localeIndex = 0; localeIndex < $scope.w.selectedMetadata.length; localeIndex++) {
			$scope.entity.locales[localeIndex].metadata = [];
			for (let metadateIndex in $scope.w.selectedMetadata[localeIndex]) {
				// not synced
				if (!!$scope.w.selectedMetadata[localeIndex][metadateIndex].metadataNameId) {
					$scope.entity.locales[localeIndex].metadata.push({
						identifier: $scope.w.selectedMetadata[localeIndex][metadateIndex].identifier,
						metadataNameId: $scope.w.selectedMetadata[localeIndex][metadateIndex].metadataNameId,
						name: $scope.w.selectedMetadata[localeIndex][metadateIndex].name,
						dropDownValueId: $scope.w.selectedMetadata[localeIndex][metadateIndex].dropDownValueId,
						position: $scope.w.selectedMetadata[localeIndex][metadateIndex].metadataObjects.position,
						productMetadataValues: defineProductMetadataValues(
							localeIndex,
							metadateIndex,
							!$scope.w.sync[metadateIndex] &&
							!$scope.isIndexBasedMeta($scope.w.selectedMetadata[0][metadateIndex])
								? undefined
								: $scope.w.selectedMetadata[0][metadateIndex]
						),
					});
				}
			}
		}

		for (let loc in $scope.tabLocales) {
			if (
				!!$scope.entity.locales[loc] &&
				!!$scope.entity.locales[loc].name &&
				!!$scope.entity.locales[loc].metadata
			) {
				$scope.entity.locales[loc].metadata = $scope.entity.locales[loc].metadata.filter(
					(x) => !!x.metadataNameId
				);

				$scope.entity.locales[loc].localeId = $scope.tabLocales[loc].id;
				$scope.entity.locales[loc].metadataSets = $scope.EntityMetadataSets[loc].metadataSets;
			}
		}

		$scope.entity.locales = $scope.entity.locales.filter((x) => !!x.name);

		for (let item of $scope.entity.locales) {
			if (
				!!item.metadataSets &&
				!!item.metadataSets.length &&
				item.metadataSets[0].productMetadataValueSets.length === 0
			) {
				item.metadataSets = [];
			}
		}

		var entityService = $injector.get('EntityCrudService');
		entityService.setType('Product');

		switch ($scope.entity.productType) {
			case 'SIMPLE':
				$scope.entity.type = 'simples';
				break;
			case 'CONFIGURABLE':
				$scope.entity.type = 'configurables';
				break;
			case 'BUNDLE':
				$scope.entity.type = 'bundles';
				break;
			default:
				return;
		}

		if ($scope.entity.id) {
			entityService.update($scope.entity).then(function () {
				switch ($scope.entity.productType) {
					case 'SIMPLE':
						// $state.go('root.productSimple', { id: response.id });
						NavigationService.changeState('root.productSimple', $scope.entity.id);
						break;
					case 'BUNDLE':
						// $state.go('root.productBundle', { id: response.id });
						NavigationService.changeState('root.productBundle', $scope.entity.id);
						break;
					case 'CONFIGURABLE':
						// $state.go('root.productConfig', { id: response.id });
						NavigationService.changeState('root.productConfig', $scope.entity.id);
						break;
				}
			});
		} else {
			entityService.create($scope.entity).then(function (response) {
				switch ($scope.entity.productType) {
					case 'SIMPLE':
						$state.go('root.productSimple', { id: response.id });
						break;
					case 'BUNDLE':
						$state.go('root.productBundle', { id: response.id });
						break;
					case 'CONFIGURABLE':
						$state.go('root.productConfig', { id: response.id });
						break;
				}
			});
		}
	};
}
;
