define('modules/wawi/product/controllers/tabs/MetadataTabController',[], function () {
	'use strict';

	return (
		$parentScope,
		$scope,
		{ BASE_TEMPLATES_PATH, MetadataSetUnpagedService, MetadataService, MetadataUnpagedService }
	) => {
		$parentScope.formTabs.push({
			label: 'FIELDS.PRODUCT_FEATURES',
			template: BASE_TEMPLATES_PATH + '/wawi/product/form-metadata.html',
		});

		$scope.metadataObjects = [];
		$scope.availableMetadataSets = [];

		MetadataSetUnpagedService.findAll().then(function () {
			$scope.metadataSets = MetadataSetUnpagedService.getAll(true).data;
			$scope.availableMetadataSets = MetadataSetUnpagedService.getAll(true).data;
		});

		$scope.afterInit = () => {
			var countLocales = 0;
			for (var i = 0; i < $parentScope.entity.locales.length; i++) {
				if ($parentScope.entity.locales[i].metadata != undefined) {
					if ($parentScope.entity.locales[i].metadata.length > countLocales) {
						countLocales = $parentScope.entity.locales[i].metadata.length;
					}
				}
				$parentScope.entity.locales[i].metadata.sort(function (a, b) {
					if (a.position === b.position) {
						var x = a.identifier.toLowerCase(),
							y = b.identifier.toLowerCase();
						return x < y ? -1 : x > y ? 1 : 0;
					}
					if (a.position < b.position) return -1;
					if (a.position > b.position) return 1;
					return a.position - b.position;
				});
			}
		};

		$scope.postAfterInit = () => {
			MetadataUnpagedService.setAdditionalParams($parentScope.params);
			MetadataUnpagedService.findAll().then(function () {
				$scope.metadatas = MetadataUnpagedService.getAll(true).data;
				$scope.availableMetadatas = MetadataUnpagedService.getAll(true).data;

				if (!$scope.metadatas) {
					$scope.metadatas = [];
				}

				if ($parentScope.entityId != '') {
					$scope.getMetadataFromServer();
				} else {
					$parentScope.priceScope.setPriceDefaultValues();

					for (var i = 0; i < $scope.metadatas.length; i++) {
						if ($scope.metadatas[i].mandatory) {
							for (var j = 0; j < $parentScope.locales.length; j++) {
								if ($parentScope.entity.locales[j].metadata === undefined) {
									$parentScope.entity.locales[j].metadata = [];
								}
								$parentScope.entity.locales[j].metadata.push({
									metadataNameId: $scope.metadatas[i].id,
									name: $scope.metadatas[i].name,
									productMetadataValues: [],
								});
								if ($scope.metadatas[i].inputType == 'MULTISELECT') {
									for (var k = 0; k < $scope.metadata.metadataValues.length; k++) {
										$parentScope.entity.locales[j].metadata[
											$parentScope.entity.locales[j].metadata.length - 1
										].productMetadataValues.push({
											value: $scope.metadatas[i].metadataValues[k].value,
											isSet: false,
											metadataValueId: $scope.metadatas[i].metadataValues[k].id,
										});
									}
								}
							}
							MetadataService.findOne($scope.metadatas[i].id).then(function () {
								$scope.metadataObjects.push(MetadataService.getOne());
							});
						}
					}
				}
			});
		};

		$scope.beforeBaseSave = () => {
			// What is this code doing???

			for (var j = 0; j < $parentScope.locales.length; j++) {
				if ($parentScope.entity.locales[j] != undefined) {
					if ($parentScope.entity.locales[j].metadata != undefined) {
						for (var i = 0; i < $parentScope.entity.locales[j].metadata.length; i++) {
							if ($parentScope.entity.locales[j].metadata[i].productMetadataValues.length) {
								for (
									var k = 0;
									k < $parentScope.entity.locales[j].metadata[i].productMetadataValues.length;
									k++
								) {
									if (
										$parentScope.entity.locales[j].metadata[i].productMetadataValues[k].isSet ===
										false
									) {
										$parentScope.entity.locales[j].metadata[i].productMetadataValues.splice(k, 1);
										k--;
									}
								}
							} else {
								for (var k = 0; k < $scope.metadataObjects[i].locales[j].metadataValues.length; k++) {
									if ($scope.metadataObjects[i].locales[j].metadataValues[k].isSet) {
										$parentScope.entity.locales[j].metadata[i].productMetadataValues.push(
											$scope.metadataObjects[i].locales[j].metadataValues[k]
										);
									}
								}
							}
						}
					}
				}
			}
		};

		/*$scope.metadataValidate = (entity) => {
			$scope.customValidation = true;
			for (var i = 0; i < $scope.metadatas.length; i++) {
				if ($scope.metadatas[i].mandatory === true && $scope.customValidation) {
					for (let j = 0; j < entity.locales.length; j++) {
						if ($scope.customValidation) {
							for (let k = 0; k < entity.locales[j].metadata.length; k++) {
								if ($scope.metadatas[i].id === entity.locales[j].metadata[k].metadataNameId) {
									if (entity.locales[j].metadata[k].productMetadataValues.length > 0) {
										$scope.customValidation = true;
									} else {
										$scope.customValidation = false;
									}
									break;
								}
								$scope.customValidation = false;
							}
						}
					}
				}
			}
			return $scope.customValidation;
		};*/

		$scope.searchMetadatas = (e, q) => {
			$scope.availableMetadatas = $scope.metadatas.filter((x) => x.name.toLowerCase().includes(q.toLowerCase()));
		};

		$scope.searchMetadataSets = (e, q) => {
			$scope.availableMetadataSets = $scope.metadataSets.filter((x) =>
				x.name.toLowerCase().includes(q.toLowerCase())
			);
		};

		$scope.getMetaDataSet = function () {
			for (var i = 0; i < $scope.metadataSets.length; i++) {
				if ($scope.metadataSets[i].id == $parentScope.availableData.metadataSetId) {
					$scope.metadataSet = $scope.metadataSets[i];
				}
			}

			if ($scope.metadataSet != undefined) {
				for (var j = 0; j < $parentScope.locales.length; j++) {
					if ($parentScope.entity.locales[j] == undefined) {
						$parentScope.entity.locales[j] = {
							localeId: $parentScope.locales[j].id,
						};
					}
					if ($parentScope.entity.locales[j].metadata == undefined) {
						$parentScope.entity.locales[j].metadata = [];
					}
					var metadatas = $parentScope.entity.locales[j].metadata.length;
					for (var i = 0; i < $scope.metadataSet.metadataNames.length; i++) {
						var alreadyExists = false;
						for (var k = 0; k < $parentScope.entity.locales[j].metadata.length; k++) {
							if (
								$parentScope.entity.locales[j].metadata[k].metadataNameId ==
								$scope.metadataSet.metadataNames[i].id
							) {
								alreadyExists = true;
							}
						}

						if (!alreadyExists) {
							$parentScope.entity.locales[j].metadata.push({
								metadataNameId: $scope.metadataSet.metadataNames[i].id,
								productMetadataValues: [],
							});
							if ($scope.metadataSet.metadataNames[i].inputType == 'MULTISELECT') {
								for (var k = 0; k < $scope.metadataSet.metadataNames[i].metadataValues.length; k++) {
									$parentScope.entity.locales[j].metadata[
										$parentScope.entity.locales[j].metadata.length - 1
									].productMetadataValues.push({
										value: $scope.metadataSet.metadataNames[i].metadataValues[k].value,
										isSet: false,
									});
								}
							}
							MetadataService.findOne($scope.metadataSet.metadataNames[i].id).then(function () {
								$scope.metadataObjects.push(MetadataService.getOne());
							});
						}
					}
				}
			}
		};

		$scope.addMetaDataToEntity = function () {
			// find the metadate that was chosen in the selector in the list of available metadatas
			for (var i = 0; i < $scope.metadatas.length; i++) {
				if ($scope.metadatas[i].id == $parentScope.selected.metadataId) {
					$scope.metadata = $scope.metadatas[i];
				}
			}

			// make sure the entity has an object for the locale
			for (var j = 0; j < $parentScope.locales.length; j++) {
				if ($parentScope.entity.locales[j] && !$parentScope.entity.locales[j].metadata) {
					$parentScope.entity.locales[j].metadata = [];
				}
			}

			if (!$scope.metadata) {
				return;
			}

			for (var j = 0; j < $parentScope.locales.length; j++) {
				if (!$parentScope.entity.locales[j]) {
					$parentScope.entity.locales[j] = {
						localeId: $parentScope.locales[j].id,
					};
				}
				if (!$parentScope.entity.locales[j].metadata) {
					$parentScope.entity.locales[j].metadata = [];
				}

				// push the metadata object into the entity
				$parentScope.entity.locales[j].metadata.push({
					metadataNameId: $scope.metadata.id,
					name: $scope.metadata.name,
					inputType: $scope.metadata.inputType,
					identifier: $scope.metadata.identifier,
					productMetadataValues: [],
				});

				// If the metadata type is multiselect, assign the first value
				if ($scope.metadata.inputType == 'MULTISELECT') {
					for (var k = 0; k < $scope.metadata.metadataValues.length; k++) {
						$parentScope.entity.locales[j].metadata[
							$parentScope.entity.locales[j].metadata.length - 1
						].productMetadataValues.push({
							value: $scope.metadata.metadataValues[k].value,
							isSet: false,
							metadataValueId: $scope.metadata.metadataValues[k].id,
						});
					}
				}
			}

			MetadataService.findOne($scope.metadata.id).then(function () {
				$scope.metadataObjects.push(MetadataService.getOne());
			});
		};

		$scope.getMetadataFromServer = function () {
			for (var i = $scope.metadataObjects.length; i < $parentScope.entity.locales[0].metadata.length; i++) {
				if ($parentScope.entity.locales[0].metadata[i].metadataNameId != undefined) {
					MetadataService.findOne($parentScope.entity.locales[0].metadata[i].metadataNameId).then(
						function () {
							$scope.metadataObjects.push(MetadataService.getOne());
							if ($scope.metadataObjects.length == $parentScope.entity.locales[0].metadata.length) {
								for (var j = 0; j < $scope.metadataObjects.length; j++) {
									$scope.metadataObjects[j].locales.sort(function (a, b) {
										if (a.localeId < b.localeId) return -1;
										if (a.localeId > b.localeId) return 1;
										return 0;
									});
								}
								$scope.metadataObjects.sort(function (a, b) {
									if (a.position === b.position) {
										var x = a.identifier.toLowerCase(),
											y = b.identifier.toLowerCase();
										return x < y ? -1 : x > y ? 1 : 0;
									}
									if (a.position < b.position) return -1;
									if (a.position > b.position) return 1;
									return a.position - b.position;
								});
								for (var m = 0; m < $scope.metadataObjects.length; m++) {
									if ($scope.metadataObjects[m].inputType == 'MULTISELECT') {
										for (var k = 0; k < $parentScope.entity.locales.length; k++) {
											for (
												var n = 0;
												n < $scope.metadataObjects[m].locales[k].metadataValues.length;
												n++
											) {
												var foundOne = false;
												$parentScope.entity.locales[k].metadata[m].productMetadataValues.sort(
													function (a, b) {
														if (a.metadataValueId < b.metadataValueId) return -1;
														if (a.metadataValueId > b.metadataValueId) return 1;
														return 0;
													}
												);
												for (
													var p = 0;
													p <
													$parentScope.entity.locales[k].metadata[m].productMetadataValues
														.length;
													p++
												) {
													if (
														$scope.metadataObjects[m].locales[k].metadataValues[n].id ==
														$parentScope.entity.locales[k].metadata[m]
															.productMetadataValues[p].metadataValueId
													) {
														$parentScope.entity.locales[k].metadata[
															m
														].productMetadataValues[p].isSet = true;
														foundOne = true;
														break;
													}
												}
												if (!foundOne) {
													var index = 0;
													for (
														var i = 0;
														i <
														$parentScope.entity.locales[k].metadata[m].productMetadataValues
															.length;
														i++
													) {
														if (
															$scope.metadataObjects[m].locales[k].metadataValues[n].id <
															$parentScope.entity.locales[k].metadata[m]
																.productMetadataValues[i].metadataValueId
														) {
															break;
														}
														index++;
													}
													$parentScope.entity.locales[k].metadata[
														m
													].productMetadataValues.splice(
														index,
														0,
														$scope.metadataObjects[m].locales[k].metadataValues[n]
													);
													$parentScope.entity.locales[k].metadata[m].productMetadataValues[
														index
													].isSet = false;
													$parentScope.entity.locales[k].metadata[m].productMetadataValues[
														index
													].metadataValueId =
														$scope.metadataObjects[m].locales[k].metadataValues[n].id;
												}
											}
										}
									}
								}
							}
						}
					);
				}
			}
		};

		$scope.getProductMetadataValueIndexByValue = function (productMetadataValues, value) {
			for (var i = 0; i < productMetadataValues.length; i++) {
				if (productMetadataValues[i].value == value) {
					return i;
				}
			}
		};

		$scope.deleteMetadata = function (index) {
			for (var i = 0; i < $parentScope.entity.locales.length; i++) {
				$parentScope.entity.locales[i].metadata.splice(index, 1);
			}
			$scope.metadataObjects.splice(index, 1);
		};

		/**
		 * Metadata value identifier is deleted when user inputs in text-type field. If identifier is passed to backend,
		 * it will update metadata value. We don't want to that because metadata value can be assigned to other products too.
		 */
		$scope.deleteProductMetadataValueIdentifier = (productMetadataValue) => {
			if (!productMetadataValue) {
				return;
			}

			productMetadataValue.identifier = undefined;
		};

		$scope.generateIdentifierUID = function () {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
					.toString(16)
					.substring(1);
			}

			return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		};
	};
});

