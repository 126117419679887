define('directives/infinity/Infinity',['app', 'services/LocaleService'], function (app) {
	'use strict';
	app.directive('ngInfinity', [
		'LocaleService',
		function (LocaleService) {
			return {
				require: 'ngModel',
				link: function ($scope, element, attrs, modelCtrl) {
					//convert data from view format to model format
					$scope.currentLocale = LocaleService.getCurrent();
					$scope.infinity = '';

					$scope.$watch('currentLocale', function (newValue, oldValue) {
						if (!newValue) {
							return;
						}
						switch (newValue.locale) {
							case 'de':
								$scope.infinity = 'Beliebig';
								break;
							case 'en':
								$scope.infinity = 'Infinity';
								break;
							case 'fr':
								$scope.infinity = 'et au-delà';
								break;
							case 'cz':
								$scope.infinity = 'Jakýkoli';
								break;
							default:
								$scope.infinity = 'Biliebig';
						}
					});

					modelCtrl.$parsers.push(function (inputValue) {
						var transformedInput;
						if (inputValue == $scope.infinity) {
							transformedInput = '-1';
						} else {
							transformedInput = inputValue;
						}

						return transformedInput;
					});

					//convert data from model format to view format
					modelCtrl.$formatters.push(function (data) {
						var transformedInput;
						if (data == '-1') {
							transformedInput = $scope.infinity;
						} else {
							transformedInput = data;
						}

						/*if (transformedInput != inputValue) {
						 modelCtrl.$setViewValue(transformedInput);
						 modelCtrl.$render();
						 }*/

						return transformedInput;
					});
				},
			};
		},
	]);
});

